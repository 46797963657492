import React, { Suspense, useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { createStore, applyMiddleware, compose } from 'redux';
import RootReducer from './reducer/index'
import rootSaga from './sagas/index'
import initialState from './store/initialState'
import createSagaMiddleware from 'redux-saga'
import { Helmet } from 'react-helmet';

import "./App.css";

import "../src/rels_assets/font/flaticon.css";
import "../src/rels_assets/css/fontawesome-all.min.css";
import "../src/rels_assets/css/fontawesome-5-all.min.css";
import "../src/rels_assets/css/font-awesome.min.css";
import "../src/rels_assets/css/search.css";
import "../src/rels_assets/css/animate.css";

import "../src/rels_assets/css/magnific-popup.css";
import "../src/rels_assets/css/lightcase.css";
import "../src/rels_assets/css/bootstrap.min.css";
import "../src/rels_assets/css/menu.css";
import "../src/rels_assets/css/slick.css";
import "../src/rels_assets/css/maps.css";
import "../src/rels_assets/css/nice-select.css";
// import "../src/rels_assets/js/nice-select.js";
// import "jquery-nice-select/css/style.css";

// import "../src/rels_assets/css/colors/pink.css";

import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
// import "./rels_assets/js/mmenu.js";

import Header from "./components/common/header";
import Footer from "./components/common/footer";
import PageNotFound from "./components/common/pagenotfound";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import resources from "./routes";
import Search from './components/search';
import Page from "./components/pages";
import AboutAs from "./components/about-us";
import AboutUs from "./components/about-us";
import ContactUs from "./components/contact-us";
import PropertyDetails from "./components/property-detail/propertyDetail";
import SystemMessage from "./components/notifications/system/components/SystemMessage";
import CustomNav from "./CustomNav";
import ScrollToTop from "react-scroll-to-top";
import DoubleArrow from "./components/common/DoubleArrow";
import ProjectsList from "./components/projects-list/projectsList";
import PrivacyPolicy from "./components/privacy-policy";
import TermsCondition from "./components/terms-condition";
import Achievement from "./components/achievement";

import "../src/rels_assets/css/styles.css";
import PropertyByArea from "./components/common/propertyByArea";
import Preleased from "./components/Pre-leased Property";
import MailPropertyDetails from "./components/property-detail/MailPropertyDetail";
import CustomerList from "./components/customers/customer-list";
if (process.env.REACT_APP_ENDPOINT == "eliterealtyservices") {
    import("../src/rels_assets/css/colors/elite.css");
} else if (process.env.REACT_APP_ENDPOINT == "nextsteprealty") {
    import("../src/rels_assets/css/colors/nextstep.css");
} else if (process.env.REACT_APP_ENDPOINT == "iconicrealty") {
    import("../src/rels_assets/css/colors/iconicrealty.css");
} else if (process.env.REACT_APP_ENDPOINT == "aonespace") {
    import("../src/rels_assets/css/colors/aonespace.css");
} else if (process.env.REACT_APP_ENDPOINT == "amaron") { 
    import("../src/rels_assets/css/colors/amaron.css")
}else if (process.env.REACT_APP_ENDPOINT == "sunrealty") {
    import("../src/rels_assets/css/colors/sunrealty.css")
} else if(process.env.REACT_APP_ENDPOINT == "madhuli") {
    import("../src/rels_assets/css/colors/madhuli.css")
} else if(process.env.REACT_APP_ENDPOINT == "acreagewealthcare") {
    import("../src/rels_assets/css/colors/acreagewealthcare.css")
} else if(process.env.REACT_APP_ENDPOINT == "aakarrealtor"){
    import("../src/rels_assets/css/colors/aakarrealtor.css")
} else if(process.env.REACT_APP_ENDPOINT == "midtownproperty"){
    import("../src/rels_assets/css/colors/midtownproperty.css")
} else if(process.env.REACT_APP_ENDPOINT == "earthconreality"){
    import("../src/rels_assets/css/colors/earthconreality.css")
} else if(process.env.REACT_APP_ENDPOINT == "rbrealtors"){
    import("../src/rels_assets/css/colors/rbrealtors.css")
}


const App = () => {
    const dispatch = useDispatch();
    const menu = useSelector(store => store.commonPageData.menu.length > 0 ? store.commonPageData.menu : store.commonPageData.menu)
    const [setting, setSetting] = useState([]);
    const [logo, setLogo] = useState("");

    useEffect(() => {
        if (menu && menu.code == 0) {
            setSetting(menu.data.site_setting);
            setLogo(menu?.data?.site_setting?.icon_logo);
        }
    }, [menu]);

    useEffect(() => {
        AOS.init();
    }, []);
    
    return (
        <>
            <Helmet>
                <title>{(menu && menu?.data && Object.keys(menu?.data).length > 0) ? `${menu.data?.site_setting?.site_name}` : "Real Estate"}</title>
                {menu?.data &&
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org/",
                            "@type": "Website",
                            "name": menu?.data?.site_setting?.site_name,
                            "description": menu?.data?.site_setting?.name,
                            "image": logo,
                        })}
                    </script>
                }

                <meta property="og:type" content="website" />
                <meta property="og:site_name" content={`${(menu && menu?.data && Object.keys(menu?.data).length > 0) ? menu?.data?.site_setting?.site_name : "Real Estate"}`}/>
                <meta property="og:url" content={menu?.data?.site_setting?.site_url} />
                <meta property="og:title" content={`${(menu && menu?.data && Object.keys(menu?.data).length > 0) ? menu?.data?.site_setting?.site_name : "Real Estate"}`} />
                <meta property="og:description" content={menu && (menu?.data && Object.keys(menu?.data).length > 0) ? menu?.data?.site_setting?.name : 'Real Estate'} />
                <meta property="og:image" content={logo} />
                <meta property="og:locale" content="en"/>

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={menu?.data?.site_setting?.site_url} />
                <meta property="twitter:title" content={`${(menu && menu?.data && Object.keys(menu?.data).length > 0) ? menu?.data?.site_setting?.site_name : "Real Estate"}`} />
                <meta property="twitter:description" content={menu && (menu?.data && Object.keys(menu?.data).length > 0) ? menu?.data?.site_setting?.name : 'Real Estate'} />
                <meta name="twitter:site" content={menu && (menu?.data && Object.keys(menu?.data).length > 0) ? menu?.data?.site_setting?.twitter_url : ""} />
                <meta name="twitter:creator" content={menu && (menu?.data && Object.keys(menu?.data).length > 0) ? menu?.data?.site_setting?.name : ""} />  
                <meta property="twitter:image" content={logo} />
            </Helmet>

            {/* <Provider store={store}> */}
            <SystemMessage />
            <Suspense fallback={<Loading />}>
                <BrowserRouter basename={`${process?.env?.PUBLIC_URL}`}>
                    <CustomNav />
                    {/* <SystemMessage/> */}
                    <div id="wrapper" className="mm-page mm-slideout">
                        <Header />
                        <Switch>
                            {resources.map(
                                ({ route, component: RouteComponent, i }) => {
                                    return (
                                        <Route
                                            key={route}
                                            exact
                                            path={route}
                                            render={(renderProps) => (
                                                <RouteComponent
                                                    {...renderProps}
                                                />
                                            )}
                                        />
                                    );
                                }
                            )}
                            <Route
                                render={(props) => <Search {...props} />}
                                path="/all/property"
                            />
                            <Route
                                render={(props) => <Search {...props} />}
                                path="/hot-property"
                            />
                            <Route
                                render={(props) => <Preleased  />}
                                path="/preleased-property"
                            />
                            <Route
                                render={(props) => <Search {...props} />}
                                path="/all/property"
                            />
                            <Route
                                render={(props) => <PropertyDetails {...props} />}
                                path="/property/:id/"
                            />
                            <Route 
                                render={(props) => <MailPropertyDetails {...props} />}
                                path="/:uid/property/"
                            />
                            <Route
                                render={(props) => <Search {...props} />}
                                path="/property"
                            />
                            <Route
                                render={(props) => <ProjectsList {...props} />}
                                path="/building/project"
                            />
                            <Route
                                render={(props) => <AboutUs {...props} />}
                                path="/page/about-us"
                            />
                            <Route
                                render={(props) => <ContactUs {...props} />}
                                path='/page/contact-us'
                            />
                            <Route 
                                render={(props) => <CustomerList {...props} />}
                                path="/page/customer-list"
                            />
                            <Route
                                render={(props) => <PrivacyPolicy {...props} />}
                                path="/page/privacy-policy"
                            />
                            <Route
                                render={(props) => <TermsCondition {...props} />}
                                path="/page/terms-&-conditions"
                            />
                            <Route
                                render={(props) => <Achievement {...props} />}
                                path="/page/achievements"
                            />
                            <Route
                                render={(props) => <Page {...props} />}
                                path="/page/:id/"
                            />

                            <Route component={PageNotFound}></Route>
                        </Switch>
                        {
                            setting && setting.areas_of_city && setting.areas_of_city == "yes" &&
                            <PropertyByArea />
                        }
                        <Footer />
                        <ScrollToTop style={{ height: "auto", width: "auto" }} smooth className="go-up" component={<DoubleArrow />} />
                    </div>
                </BrowserRouter>
            </Suspense>
            {/* </Provider> */}
        </>
    );
}
function Loading() {
    return (
        <div id="preloader">
            <div id="status">
                <div className="status-mes"></div>
            </div>
        </div>
    );
}
export default App;
